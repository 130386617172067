
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface gifts{
    user_id:number
    amount:number
    store:number
}


class HttpGifts extends Http{
    give_gifts = (data:gifts)=>{
        return this.post(data)
    }
}

export const api_gifts =  new HttpGifts('/admin/gifts/')
